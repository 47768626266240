import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Website gesucht?
			</title>
			<meta name={"description"} content={"Websites by CLEM1"} />
			<meta property={"og:title"} content={"Websites für jedermann by CLEM1"} />
			<meta property={"og:description"} content={"Keine Website, kein Problem ist mein Motto. Ich biete eine moderne Website für jedermann zu attraktiven Preisen. Einfache Websites, oder aufwendiges Design? Kein Problem!"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
			<Override slot="box1" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride4" />
			<Override slot="box2" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride3" />
		</Components.Header>
		<Components.Hero
			sm-overflow-x="visible"
			sm-display="flex"
			sm-overflow-y="visible"
			sm-flex-direction="row"
			sm-min-height="550px"
			md-display="flex"
		>
			<Override slot="link" href="/leistungen" />
			<Override slot="image" src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/9c358b68-f339-41a4-3059-5d5045489600/public" />
			<Override slot="text1">
				Sie benötigen eine moderne Website?{" "}
				<br />
				Wir kümmern uns darum.
			</Override>
			<Override slot="text" />
			<Override slot="link1" />
			<Override
				slot="SectionContent"
				max-height="355px"
				sm-overflow-x="visible"
				sm-flex-direction="row"
				sm-overflow-y="visible"
				md-max-height="inherit"
			/>
			<Override slot="box2" max-height="355px" sm-max-height="355px" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-richtigesgrau">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/bb606e6b-853f-4af1-4ea8-cedb39e7a000/public"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						hover-box-shadow="5px 5px 5px 0 rgba(0, 0, 0, 0.33)"
						hover-transition="all --transitionDuration-fast ease 10ms"
						hover-transform="scale(1.02)"
						transition="all --transitionDuration-normal ease 10ms"
					/>
					<Image
						src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/e6db0c55-21af-48cd-2c02-5f6da0897f00/public"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						hover-box-shadow="5px 5px 5px 0 rgba(0, 0, 0, 0.33)"
						hover-transition="all --transitionDuration-fast ease 10ms"
						hover-transform="scale(1.02)"
						transition="all --transitionDuration-normal ease 10ms"
					/>
					<Image
						src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/c13caf2c-bb10-4d49-2cf6-3cad13e4ed00/public"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						hover-box-shadow="5px 5px 5px 0 rgba(0, 0, 0, 0.33)"
						hover-transition="all --transitionDuration-fast ease 10ms"
						hover-transform="scale(1.02)"
						transition="all --transitionDuration-normal ease 10ms"
					/>
					<Image
						src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/4560f009-4dcc-46d9-1b6e-ffbfa59d9300/public"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						hover-box-shadow="5px 5px 5px 0 rgba(0, 0, 0, 0.33)"
						hover-transition="all --transitionDuration-fast ease 10ms"
						hover-transform="scale(1.02)"
						transition="all --transitionDuration-normal ease 10ms"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Components.QuarklycommunityKitLoopText slides="günstig,modern,einfach" font="--headline2">
					<Override slot="Before Text" color="--proweiss">
						Ihr Webauftriftt kann
					</Override>
					<Override slot="After Text" color="--proweiss">
						sein.
					</Override>
					<Override slot="Looped Text" color="--pr0Orange" />
				</Components.QuarklycommunityKitLoopText>
				<Text margin="0px 0px 0px 0px" color="--proweiss" font="normal 400 18px/1.5 --fontFamily-googleInter" lg-text-align="center">
					Wer sich online gut präsentiert und auf seiner Website für Kunden relevante Informationen liefert, steigert seine Chance neue Kunden zu gewinnen und somit seinen Umsatz.
					<br />
					{"\n"}Ein Webauftritt ist heut zu Tage essentiell. Mit einer Website erreichen Sie deutlich mehr Kundschaft. Wir sorgen für Ihre Webpräsenz.{"\n\n\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="socialMediaOverride1" />
			<Override slot="socialMediaOverride" />
			<Override slot="link8" />
			<Override slot="link3" />
			<Override slot="link9" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"6349caa26459a595bf58107e"}>
				{"overflow: hidden;"}
			</style>
		</RawHtml>
	</Theme>;
});